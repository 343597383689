import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { GameClockComponent } from './game-clock/game-clock.component';
import { IceRinkComponent } from './ice-rink/ice-rink.component';
import { CountdownTimePipe } from './pipes/countdown-time.pipe';
import { DecimalValuePipe } from './pipes/decimal-value.pipe';
import { GameClockTimePipe } from './pipes/game-clock-time.pipe';
import { MMSSTimePipe } from './pipes/hhmm-time.pipe';
import { PlayerNumberTrimPipe } from './pipes/player-number-trim.pipe';
import { SafeNumberPipe } from './pipes/safenumber.pipe';
import { TimePipe } from './pipes/timeconverter.pipe';
import { PlayerFilterComponent } from './player-filter/player-filter.component';
import { PlayerValidatorDirective } from './player-validator.directive';
import { PlayerSummaryComponent } from './player-summary/player-summary.component';
import { PlayerIdValidatorDirective } from './playerid-validator.directive';
import { SeasonCollectionEffortComponent } from './season-collection-effort/season-collection-effort.component';
import { StrengthStateComponent } from './strength-state/strength-state.component';
import { CollectionEffortComponent } from './collection-stats/collection-effort.component';
import { AppVgTimeDisplayComponent } from './vg-time-display';
import { AppVgScrubBarCurrentTimeComponent } from './vg-scrub-bar-current-time';
import { PuckPossessionStateComponent } from './puck-possession-state/puck-possession-state.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectAllComponent } from './multi-select-all/multi-select-all.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { TimeCodePipe } from '../time-on-ice/pipes/timecode.pipe';
import { GameNavigationComponent } from './game-navigation/game-navigation.component';
import { PeriodComponent } from './period/period.component';
import { CameraAnglesSelectDialogComponent } from './camera-angles-select/camera-angles-select-dialog.component';
import { PlayerPositionPipe } from './pipes/player-position.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatTabsModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    FormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    RouterModule
  ],
  declarations: [
    CountdownTimePipe,
    StrengthStateComponent,
    GameClockComponent,
    PuckPossessionStateComponent,
    TimePipe,
    MMSSTimePipe,
    GameClockTimePipe,
    SafeNumberPipe,
    DecimalValuePipe,
    PlayerNumberTrimPipe,
    IceRinkComponent,
    FileUploadDialogComponent,
    PlayerFilterComponent,
    PlayerSummaryComponent,
    PlayerValidatorDirective,
    PlayerIdValidatorDirective,
    CollectionEffortComponent,
    SeasonCollectionEffortComponent,
    AppVgTimeDisplayComponent,
    AppVgScrubBarCurrentTimeComponent,
    StarRatingComponent,
    MultiSelectAllComponent,
    ConfirmationDialogComponent,
    UploadImageComponent,
    TimeCodePipe,
    GameNavigationComponent,
    PeriodComponent,
    CameraAnglesSelectDialogComponent,
    PlayerPositionPipe
  ],
  exports: [
    CountdownTimePipe,
    StrengthStateComponent,
    GameClockComponent,
    TimePipe,
    MMSSTimePipe,
    GameClockTimePipe,
    SafeNumberPipe,
    DecimalValuePipe,
    PlayerNumberTrimPipe,
    IceRinkComponent,
    FileUploadDialogComponent,
    PlayerFilterComponent,
    PlayerValidatorDirective,
    PlayerIdValidatorDirective,
    VgBufferingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgStreamingModule,
    AppVgTimeDisplayComponent,
    AppVgScrubBarCurrentTimeComponent,
    PuckPossessionStateComponent,
    StarRatingComponent,
    MatIconModule,
    MultiSelectAllComponent,
    ConfirmationDialogComponent,
    UploadImageComponent,
    TimeCodePipe,
    GameNavigationComponent,
    PeriodComponent,
    CameraAnglesSelectDialogComponent,
    PlayerPositionPipe
  ]
})
export class SharedModule {}
