import { createFeature, createReducer, on } from '@ngrx/store';
import {
  deflectorPlayerNumberChange,
  detectionsChange,
  eventIdChange,
  eventTypeChange,
  faceoffOpponentPlayerNumberChange,
  faceoffOutcomePositionChange,
  assist1PlayerNumberChange,
  fouledPlayerNumberChange,
  gameTimeChange,
  hasNetTrafficChange,
  hasScreenChange,
  highlightPlaybackChange,
  highlightRatingChange,
  highlightTypeChange,
  interruptionTypeChange,
  isAwayTeamEmptyNetChange,
  isBlockedChange,
  isHomeTeamEmptyNetChange,
  netImpactChange,
  netTrafficCauserPlayerNumberChange,
  oddMenRushDetailChange,
  passOutcomeChange,
  passReceiverPlayerNumberChange,
  passReceiverPositionChange,
  passTypeChange,
  penaltyIdChange,
  penaltyDurationChange,
  penaltyTypeChange,
  periodChange,
  playerNumberChange,
  positionChange,
  resetState,
  resetHardState,
  screenerPlayerNumberChange,
  assist2PlayerNumberChange,
  selectedTrackIdChange,
  shotBlockerPlayerNumberChange,
  shotBlockerPositionChange,
  shotOutcomeChange,
  shotScenarioChange,
  shotTypeChange,
  strengthStateChange,
  teamChange,
  teamFaceoffOutcomeChange,
  videoTagChange,
  videoTimeChange
} from '../actions/game-event.action';
import {
  EventType,
  GamePeriod,
  HighlightPlayback,
  HighlightRating,
  HighlightType,
  InterruptionType,
  NetImpactPositionCoordinate,
  OddMenRushDetail,
  PassOutcome,
  PassType,
  PenaltyDuration,
  PenaltyType,
  PositionCoordinate,
  ShotOutcome,
  ShotScenario,
  ShotType,
  StrengthState,
  TeamFaceOffOutcome,
  VideoTagType
} from '../../domain/game-event';
import { Detection } from '../../services/detection';
import ObjectId from 'bson-objectid';

export const gameEventFeatureKey = 'gameEvent';

export interface GameEventState {
  _id: string;
  videoTime: number;
  gameTime: number;
  period: GamePeriod;
  strengthState: StrengthState;
  eventType: EventType;
  team: string;
  isHomeTeamEmptyNet: boolean;
  isAwayTeamEmptyNet: boolean;
  oddMenRushDetail: OddMenRushDetail;

  position: PositionCoordinate;
  passReceiverPosition: PositionCoordinate;
  shotBlockerPosition: PositionCoordinate;
  faceoffOutcomePosition: PositionCoordinate;

  passType: PassType;
  passOutcome: PassOutcome;

  shotOutcome: ShotOutcome;
  shotScenario: ShotScenario;
  shotType: ShotType;

  is_blocked: string;
  has_net_traffic: string;
  has_screen: string;

  teamFaceOffOutcome: TeamFaceOffOutcome;

  interruption_type: InterruptionType;

  videoTag: VideoTagType;

  playerNumber: string;
  passReceiverPlayerNumber: string;
  shotBlockerPlayerNumber: string;
  deflectorPlayerNumber: string;
  screenerPlayerNumber: string;
  netTrafficCauserPlayerNumber: string;
  faceOffOpponentPlayerNumber: string;
  fouledPlayerNumber: string;
  assist1PlayerNumber: string;
  assist2PlayerNumber: string;

  selectedTrackId: number;

  detections: Detection[];

  highlightType: HighlightType;
  highlightPlayback: HighlightPlayback;
  highlightRating: HighlightRating;

  netImpact: NetImpactPositionCoordinate;

  penaltyType: PenaltyType;
  penaltyId: ObjectId;
  penaltyDuration: PenaltyDuration;
}

export const INITIAL_GAME_EVENT_STATE: GameEventState = {
  _id: null,
  videoTime: 0,
  gameTime: 0,
  period: '1',
  strengthState: '5-5',
  eventType: null,
  team: undefined,
  isHomeTeamEmptyNet: false,
  isAwayTeamEmptyNet: false,
  oddMenRushDetail: undefined,

  position: undefined,
  passReceiverPosition: undefined,
  shotBlockerPosition: undefined,
  faceoffOutcomePosition: undefined,

  passType: undefined,
  passOutcome: undefined,

  shotOutcome: undefined,
  shotScenario: undefined,
  shotType: undefined,

  is_blocked: undefined,
  has_net_traffic: undefined,
  has_screen: undefined,

  teamFaceOffOutcome: undefined,

  interruption_type: undefined,

  videoTag: undefined,

  playerNumber: undefined,
  passReceiverPlayerNumber: undefined,
  shotBlockerPlayerNumber: undefined,
  deflectorPlayerNumber: undefined,
  screenerPlayerNumber: undefined,
  netTrafficCauserPlayerNumber: undefined,
  faceOffOpponentPlayerNumber: undefined,
  fouledPlayerNumber: undefined,
  assist1PlayerNumber: undefined,
  assist2PlayerNumber: undefined,

  selectedTrackId: undefined,

  detections: undefined,

  highlightType: undefined,
  highlightPlayback: undefined,
  highlightRating: undefined,

  netImpact: undefined,

  penaltyType: undefined,
  penaltyId: undefined,
  penaltyDuration: undefined
};

const gameReducer = createReducer(
  INITIAL_GAME_EVENT_STATE,
  on(eventIdChange, (state, { _id }) => ({
    ...state,
    _id
  })),
  on(gameTimeChange, (state, { gameTime }) => ({
    ...state,
    gameTime
  })),
  on(videoTimeChange, (state, { videoTime }) => ({
    ...state,
    videoTime
  })),
  on(periodChange, (state, { period }) => ({
    ...state,
    period
  })),
  on(strengthStateChange, (state, { strengthState }) => ({
    ...state,
    strengthState
  })),
  on(eventTypeChange, (state, { eventType }) => ({
    ...state,
    eventType
  })),
  on(teamChange, (state, { team }) => ({
    ...state,
    team
  })),
  on(isHomeTeamEmptyNetChange, (state, { isHomeTeamEmptyNet }) => ({
    ...state,
    isHomeTeamEmptyNet
  })),
  on(isAwayTeamEmptyNetChange, (state, { isAwayTeamEmptyNet }) => ({
    ...state,
    isAwayTeamEmptyNet
  })),
  on(oddMenRushDetailChange, (state, { oddMenRushDetail }) => ({
    ...state,
    oddMenRushDetail
  })),
  on(positionChange, (state, { position }) => ({
    ...state,
    position
  })),
  on(passReceiverPositionChange, (state, { passReceiverPosition }) => ({
    ...state,
    passReceiverPosition
  })),
  on(shotBlockerPositionChange, (state, { shotBlockerPosition }) => ({
    ...state,
    shotBlockerPosition
  })),
  on(faceoffOutcomePositionChange, (state, { faceoffOutcomePosition }) => ({
    ...state,
    faceoffOutcomePosition
  })),
  on(passTypeChange, (state, { passType }) => ({
    ...state,
    passType
  })),
  on(passOutcomeChange, (state, { passOutcome }) => ({
    ...state,
    passOutcome
  })),
  on(shotOutcomeChange, (state, { shotOutcome }) => ({
    ...state,
    shotOutcome
  })),
  on(shotScenarioChange, (state, { shotScenario }) => ({
    ...state,
    shotScenario
  })),
  on(shotTypeChange, (state, { shotType }) => ({
    ...state,
    shotType
  })),
  on(isBlockedChange, (state, { is_blocked }) => ({
    ...state,
    is_blocked
  })),
  on(hasNetTrafficChange, (state, { has_net_traffic }) => ({
    ...state,
    has_net_traffic
  })),
  on(hasScreenChange, (state, { has_screen }) => ({
    ...state,
    has_screen
  })),
  on(teamFaceoffOutcomeChange, (state, { teamFaceOffOutcome }) => ({
    ...state,
    teamFaceOffOutcome
  })),
  on(interruptionTypeChange, (state, { interruption_type }) => ({
    ...state,
    interruption_type
  })),
  on(videoTagChange, (state, { videoTag }) => ({
    ...state,
    videoTag
  })),
  on(playerNumberChange, (state, { playerNumber }) => ({
    ...state,
    playerNumber
  })),
  on(passReceiverPlayerNumberChange, (state, { passReceiverPlayerNumber }) => ({
    ...state,
    passReceiverPlayerNumber
  })),
  on(assist1PlayerNumberChange, (state, { assist1PlayerNumber }) => ({
    ...state,
    assist1PlayerNumber
  })),
  on(assist2PlayerNumberChange, (state, { assist2PlayerNumber }) => ({
    ...state,
    assist2PlayerNumber
  })),
  on(shotBlockerPlayerNumberChange, (state, { shotBlockerPlayerNumber }) => ({
    ...state,
    shotBlockerPlayerNumber
  })),
  on(deflectorPlayerNumberChange, (state, { deflectorPlayerNumber }) => ({
    ...state,
    deflectorPlayerNumber
  })),
  on(screenerPlayerNumberChange, (state, { screenerPlayerNumber }) => ({
    ...state,
    screenerPlayerNumber
  })),
  on(
    netTrafficCauserPlayerNumberChange,
    (state, { netTrafficCauserPlayerNumber }) => ({
      ...state,
      netTrafficCauserPlayerNumber
    })
  ),
  on(
    faceoffOpponentPlayerNumberChange,
    (state, { faceOffOpponentPlayerNumber }) => ({
      ...state,
      faceOffOpponentPlayerNumber
    })
  ),
  on(fouledPlayerNumberChange, (state, { fouledPlayerNumber }) => ({
    ...state,
    fouledPlayerNumber
  })),
  on(selectedTrackIdChange, (state, { selectedTrackId }) => ({
    ...state,
    selectedTrackId
  })),
  on(detectionsChange, (state, { detections }) => ({
    ...state,
    detections
  })),
  on(highlightTypeChange, (state, { highlightType }) => ({
    ...state,
    highlightType
  })),
  on(highlightPlaybackChange, (state, { highlightPlayback }) => ({
    ...state,
    highlightPlayback
  })),
  on(highlightRatingChange, (state, { highlightRating }) => ({
    ...state,
    highlightRating
  })),
  on(netImpactChange, (state, { netImpact }) => ({
    ...state,
    netImpact
  })),
  on(penaltyTypeChange, (state, { penaltyType }) => ({
    ...state,
    penaltyType
  })),
  on(penaltyIdChange, (state, { penaltyId }) => ({
    ...state,
    penaltyId
  })),
  on(penaltyDurationChange, (state, { penaltyDuration }) => ({
    ...state,
    penaltyDuration
  })),
  on(resetState, (state, payload) => {
    let result = Object.assign({}, INITIAL_GAME_EVENT_STATE, {
      gameTime: state.gameTime,
      videoTime: state.videoTime,
      strengthState: state.strengthState,
      isHomeTeamEmptyNet: state.isHomeTeamEmptyNet,
      isAwayTeamEmptyNet: state.isAwayTeamEmptyNet
    });

    if (payload.period) {
      result = Object.assign({}, result, { period: payload.period });
    }

    if (payload.team) {
      result = Object.assign({}, result, { team: payload.team });
    }

    return result;
  }),
  on(resetHardState, (state, payload) =>
    Object.assign({}, INITIAL_GAME_EVENT_STATE)
  )
);

const gameFeature = createFeature({
  name: gameEventFeatureKey,
  reducer: gameReducer
});

export const {
  name,
  reducer,
  select_id,
  selectGameTime,
  selectVideoTime,
  selectPeriod,
  selectStrengthState,
  selectEventType,
  selectTeam,
  selectIsHomeTeamEmptyNet,
  selectIsAwayTeamEmptyNet,
  selectOddMenRushDetail,
  selectPosition,
  selectPassReceiverPosition,
  selectShotBlockerPosition,
  selectFaceoffOutcomePosition,
  selectPassType,
  selectPassOutcome,
  selectShotOutcome,
  selectShotScenario,
  selectShotType,
  selectIs_blocked,
  selectHas_net_traffic,
  selectHas_screen,
  selectTeamFaceOffOutcome,
  selectInterruption_type,
  selectVideoTag,
  selectPlayerNumber,
  selectPassReceiverPlayerNumber,
  selectShotBlockerPlayerNumber,
  selectDeflectorPlayerNumber,
  selectScreenerPlayerNumber,
  selectNetTrafficCauserPlayerNumber,
  selectFaceOffOpponentPlayerNumber,
  selectFouledPlayerNumber,
  selectSelectedTrackId,
  selectDetections,
  selectHighlightType,
  selectHighlightPlayback,
  selectHighlightRating,
  selectNetImpact,
  selectPenaltyType,
  selectPenaltyId,
  selectPenaltyDuration,
  selectAssist1PlayerNumber,
  selectAssist2PlayerNumber,
  selectGameEventState
} = gameFeature;
