import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable, throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Game, GameProgress, GameStatus, Video } from '../domain/game';
import { ValidationCheck } from '../domain/validation-check';
import { GamePeriod, periodKeyCodeMap } from '../domain/game-event';
import { QualityCheck } from '../game-quality-dialog/game-quality-dialog.component';
import { DatePipe } from '@angular/common';

export interface VideoDownloadOptions {
  fullLength: boolean;
  clipped: boolean;
  clipStart?: number;
  clipEnd?: number;
  hls?: boolean;
}

export interface TimeOnIceReport {
  qualityChecks: QualityCheck[];
  total: QualityCheck;
}

@Injectable()
export class GameService {
  private gamesUrl = environment.API_HOST + '/api/games';
  private baseUrl = environment.API_HOST + '/api';

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  /**
   * Returns total game count and games for the requested page
   */
  findGames(
    filters: any,
    page: number,
    pageSize: number,
    sortField: string,
    sortDirection: SortDirection
  ): Observable<[number, Game[]]> {
    const definedFilters = Object.keys(filters)
      .filter((k) => filters[k] != null)
      .reduce((a, k) => ({ ...a, [k]: filters[k] }), {});
    const params = new HttpParams({ fromObject: definedFilters })
      .set('page', page)
      .set('pageSize', pageSize)
      .set('sortField', sortField)
      .set('sortDirection', sortDirection)
      .set('timezoneOffset', new Date().getTimezoneOffset());
    return this.http.get<any>(this.gamesUrl, { params }).pipe(
      map((json) => {
        const count: number = json['count'];
        const games: Game[] = json['data'].map((obj) => new Game(obj));
        return [count, games] as [number, Game[]];
      }),
      catchError(this.handleError)
    );
  }

  getGame(id: string): Observable<Game> {
    const url = `${this.gamesUrl}/${id}`;
    return this.http.get(url).pipe(
      map((obj) => new Game(obj)),
      catchError(this.handleError)
    );
  }

  private handleError(httpErrorResponse: Response | any) {
    let errMsg: string;
    if (httpErrorResponse.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = httpErrorResponse.message
        ? httpErrorResponse.message
        : httpErrorResponse.toString();
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (httpErrorResponse.status === 422) {
        // validation failed
        return throwError(() => new Error(httpErrorResponse.error.message));
      } else {
        errMsg = `${httpErrorResponse.status} - ${
          httpErrorResponse.statusText || ''
        } ${httpErrorResponse.error}`;
        console.error(
          `Backend returned code ${httpErrorResponse.status}, ` +
            `body was: ${httpErrorResponse.error}`
        );
      }
    }
    return throwError(() => new Error(errMsg));
  }

  create(game: Game): Observable<any> {
    return this.http
      .post(this.gamesUrl, game)
      .pipe(catchError(this.handleError));
  }

  update(_id: string, changes: Partial<Game>): Observable<any> {
    return this.http
      .patch(this.gamesUrl, { _id, ...changes })
      .pipe(catchError(this.handleError));
  }

  delete(game: Game) {
    return this.http
      .delete(this.gamesUrl + '/' + game._id)
      .pipe(catchError(this.handleError));
  }

  duplicateGame(gameId: string, duplicateEventTypes: string[]) {
    return this.http.post<Game>(
      `${this.gamesUrl}/${gameId}/duplicate`,
      duplicateEventTypes
    );
  }

  getGameShiftsAsCSV(gameId: string): Observable<string> {
    return this.http
      .get(`${this.gamesUrl}/export-shifts-csv/${gameId}`, {
        responseType: 'text'
      })
      .pipe(catchError(this.handleError));
  }

  getGameEventsAsCSV(game: Game): Observable<string> {
    return this.http
      .get(`${this.gamesUrl}/export-csv/${game._id}`, { responseType: 'text' })
      .pipe(catchError(this.handleError));
  }

  getGameEventsAsXML(
    game: Game,
    teamId: string,
    timeOnIce: boolean,
    gameActions: boolean
  ): Observable<string> {
    return this.http
      .get(
        `${this.gamesUrl}/${game._id}/export-xml?teamId=${teamId}&time-on-ice=${timeOnIce}&game-actions=${gameActions}`,
        { responseType: 'text' }
      )
      .pipe(catchError(this.handleError));
  }

  importGameEventsAsCSV(game: Game, data: string): Observable<any> {
    return this.http
      .post(`${this.gamesUrl}/import-csv/${game._id}`, { data })
      .pipe(catchError(this.handleError));
  }

  publishFullGame(
    game: Game,
    teamId: string,
    timeOnIce: boolean,
    gameActions: boolean
  ): Observable<void> {
    return this.http.post<any>(
      `${this.gamesUrl}/${game._id}/publish-sihf?time-on-ice=${timeOnIce}&game-actions=${gameActions}`,
      { teamId, timeOnIce, gameActions }
    );
  }

  getAllPlayers(game: Game): string[] {
    return this.getHomeTeamPlayers(game)
      .concat(this.getAwayTeamPlayers(game))
      .filter(function (n) {
        return n !== undefined;
      });
  }

  getPlayers(game: Game, team: string) {
    const isHomeTeam = team === game.homeTeam;
    return isHomeTeam
      ? this.getHomeTeamPlayers(game)
      : this.getAwayTeamPlayers(game);
  }

  getAwayTeamPlayers(game: Game): string[] {
    let awayTeamPlayers = [game.awayGoalkeeper, game.awayBackupGoalkeeper];
    awayTeamPlayers = awayTeamPlayers.concat(this.getAwayTeamDefensemen(game));
    awayTeamPlayers = awayTeamPlayers.concat(this.getAwayTeamForwards(game));
    return awayTeamPlayers.filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAwayTeamDefensemen(game: Game) {
    return [
      game.awayFirstLineLeftDefence,
      game.awayFirstLineRightDefence,
      game.awaySecondLineLeftDefence,
      game.awaySecondLineRightDefence,
      game.awayThirdLineLeftDefence,
      game.awayThirdLineRightDefence,
      game.awayFourthLineLeftDefence,
      game.awayFourthLineRightDefence,
      game.awayFifthLineLeftDefence,
      game.awayFifthLineRightDefence,
      game.awayExtraLineDefence
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getAwayTeamForwards(game: Game) {
    return [
      game.awayFirstLineLeftForward,
      game.awayFirstLineCenter,
      game.awayFirstLineRightForward,
      game.awaySecondLineLeftForward,
      game.awaySecondLineCenter,
      game.awaySecondLineRightForward,
      game.awayThirdLineLeftForward,
      game.awayThirdLineCenter,
      game.awayThirdLineRightForward,
      game.awayFourthLineLeftForward,
      game.awayFourthLineCenter,
      game.awayFourthLineRightForward,
      game.awayFifthLineLeftForward,
      game.awayFifthLineCenter,
      game.awayFifthLineRightForward,
      game.awayExtraLineForward
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamPlayers(game: Game): string[] {
    let homeTeamPlayers = [game.homeGoalkeeper, game.homeBackupGoalkeeper];
    homeTeamPlayers = homeTeamPlayers.concat(this.getHomeTeamDefensemen(game));
    homeTeamPlayers = homeTeamPlayers.concat(this.getHomeTeamForwards(game));
    return homeTeamPlayers.filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamDefensemen(game: Game) {
    return [
      game.homeFirstLineLeftDefence,
      game.homeFirstLineRightDefence,
      game.homeSecondLineLeftDefence,
      game.homeSecondLineRightDefence,
      game.homeThirdLineLeftDefence,
      game.homeThirdLineRightDefence,
      game.homeFourthLineLeftDefence,
      game.homeFourthLineRightDefence,
      game.homeFifthLineLeftDefence,
      game.homeFifthLineRightDefence,
      game.homeExtraLineDefence,
      game.homeExtraLineForward
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamForwards(game: Game) {
    return [
      game.homeFirstLineLeftForward,
      game.homeFirstLineCenter,
      game.homeFirstLineRightForward,
      game.homeSecondLineLeftForward,
      game.homeSecondLineCenter,
      game.homeSecondLineRightForward,
      game.homeThirdLineLeftForward,
      game.homeThirdLineCenter,
      game.homeThirdLineRightForward,
      game.homeFourthLineLeftForward,
      game.homeFourthLineCenter,
      game.homeFourthLineRightForward,
      game.homeFifthLineLeftForward,
      game.homeFifthLineCenter,
      game.homeFifthLineRightForward,
      game.homeExtraLineDefence,
      game.homeExtraLineForward
    ].filter(
      (player) => player != null && player !== 'n/a' && player.trim() !== ''
    );
  }

  getHomeTeamPlayerByJerseyNumber(game: Game, jerseyNumber: string) {
    return this.getHomeTeamPlayers(game).filter(
      (player) => this.getJerseyNumberFromPlayer(player) === jerseyNumber
    )[0];
  }

  getAwayTeamPlayerByJerseyNumber(game: Game, jerseyNumber: string) {
    return this.getAwayTeamPlayers(game).filter(
      (player) => this.getJerseyNumberFromPlayer(player) === jerseyNumber
    )[0];
  }

  findPlayerByJerseyNumber(game: Game, team: string, jerseyNumber: string) {
    const players = this.getPlayers(game, team);
    return players.find(
      (player) => this.getJerseyNumberFromPlayer(player) === jerseyNumber
    );
  }

  getPlayerIdByJerseyNumber(
    game: Game,
    isHomeTeam: boolean,
    jerseyNumber: string
  ): string {
    const players = isHomeTeam
      ? this.getHomeTeamPlayersById(game)
      : this.getAwayTeamPlayersById(game);
    const result = players
      .filter((t) => t[0] != null && t[1] != null)
      .filter((player) => player[1] === jerseyNumber);
    if (result.length === 1) {
      return result[0][0];
    } else {
      return null;
    }
  }

  private getHomeTeamPlayersById(game: Game): [string, string][] {
    return [
      [game.homeGoalkeeperID, game.homeGoalkeeper],
      [game.homeBackupGoalkeeperID, game.homeBackupGoalkeeper],
      [game.homeFirstLineLeftDefenceID, game.homeFirstLineLeftDefence],
      [game.homeFirstLineRightDefenceID, game.homeFirstLineRightDefence],
      [game.homeSecondLineLeftDefenceID, game.homeSecondLineLeftDefence],
      [game.homeSecondLineRightDefenceID, game.homeSecondLineRightDefence],
      [game.homeThirdLineLeftDefenceID, game.homeThirdLineLeftDefence],
      [game.homeThirdLineRightDefenceID, game.homeThirdLineRightDefence],
      [game.homeFourthLineLeftDefenceID, game.homeFourthLineLeftDefence],
      [game.homeFourthLineRightDefenceID, game.homeFourthLineRightDefence],
      [game.homeFifthLineLeftDefenceID, game.homeFifthLineLeftDefence],
      [game.homeFifthLineRightDefenceID, game.homeFifthLineRightDefence],
      [game.homeExtraLineDefenceID, game.homeExtraLineDefence],

      [game.homeFirstLineLeftForwardID, game.homeFirstLineLeftForward],
      [game.homeFirstLineCenterID, game.homeFirstLineCenter],
      [game.homeFirstLineRightForwardID, game.homeFirstLineRightForward],
      [game.homeSecondLineLeftForwardID, game.homeSecondLineLeftForward],
      [game.homeSecondLineCenterID, game.homeSecondLineCenter],
      [game.homeSecondLineRightForwardID, game.homeSecondLineRightForward],
      [game.homeThirdLineLeftForwardID, game.homeThirdLineLeftForward],
      [game.homeThirdLineCenterID, game.homeThirdLineCenter],
      [game.homeThirdLineRightForwardID, game.homeThirdLineRightForward],
      [game.homeFourthLineLeftForwardID, game.homeFourthLineLeftForward],
      [game.homeFourthLineCenterID, game.homeFourthLineCenter],
      [game.homeFourthLineRightForwardID, game.homeFourthLineRightForward],
      [game.homeFifthLineLeftForwardID, game.homeFifthLineLeftForward],
      [game.homeFifthLineCenterID, game.homeFifthLineCenter],
      [game.homeFifthLineRightForwardID, game.homeFifthLineRightForward],
      [game.homeExtraLineForwardID, game.homeExtraLineForward]
    ];
  }

  private getAwayTeamPlayersById(game: Game): [string, string][] {
    return [
      [game.awayGoalkeeperID, game.awayGoalkeeper],
      [game.awayBackupGoalkeeperID, game.awayBackupGoalkeeper],
      [game.awayFirstLineLeftDefenceID, game.awayFirstLineLeftDefence],
      [game.awayFirstLineRightDefenceID, game.awayFirstLineRightDefence],
      [game.awaySecondLineLeftDefenceID, game.awaySecondLineLeftDefence],
      [game.awaySecondLineRightDefenceID, game.awaySecondLineRightDefence],
      [game.awayThirdLineLeftDefenceID, game.awayThirdLineLeftDefence],
      [game.awayThirdLineRightDefenceID, game.awayThirdLineRightDefence],
      [game.awayFourthLineLeftDefenceID, game.awayFourthLineLeftDefence],
      [game.awayFourthLineRightDefenceID, game.awayFourthLineRightDefence],
      [game.awayFifthLineLeftDefenceID, game.awayFifthLineLeftDefence],
      [game.awayFifthLineRightDefenceID, game.awayFifthLineRightDefence],
      [game.awayExtraLineDefenceID, game.awayExtraLineDefence],

      [game.awayFirstLineLeftForwardID, game.awayFirstLineLeftForward],
      [game.awayFirstLineCenterID, game.awayFirstLineCenter],
      [game.awayFirstLineRightForwardID, game.awayFirstLineRightForward],
      [game.awaySecondLineLeftForwardID, game.awaySecondLineLeftForward],
      [game.awaySecondLineCenterID, game.awaySecondLineCenter],
      [game.awaySecondLineRightForwardID, game.awaySecondLineRightForward],
      [game.awayThirdLineLeftForwardID, game.awayThirdLineLeftForward],
      [game.awayThirdLineCenterID, game.awayThirdLineCenter],
      [game.awayThirdLineRightForwardID, game.awayThirdLineRightForward],
      [game.awayFourthLineLeftForwardID, game.awayFourthLineLeftForward],
      [game.awayFourthLineCenterID, game.awayFourthLineCenter],
      [game.awayFourthLineRightForwardID, game.awayFourthLineRightForward],
      [game.awayFifthLineLeftForwardID, game.awayFifthLineLeftForward],
      [game.awayFifthLineCenterID, game.awayFifthLineCenter],
      [game.awayFifthLineRightForwardID, game.awayFifthLineRightForward],
      [game.awayExtraLineForwardID, game.awayExtraLineForward]
    ];
  }

  getJerseyNumberFromPlayer(player: string) {
    return player.split('-')[0].trim();
  }

  getNameFromPlayer(player: string) {
    return player.split('-')[1].trim();
  }

  getTeamNames(game: Game) {
    return [game.homeTeam, game.awayTeam];
  }

  downloadVideo(
    gameId: string,
    video: Video,
    downloadOptions: VideoDownloadOptions
  ) {
    return this.http.post<{ status: string }>(
      `${this.gamesUrl}/${gameId}/download-video`,
      {
        video,
        downloadOptions
      }
    );
  }

  syncSeason(season, league): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/games/sync-completed?season=${season}&league=${league}`,
      {}
    );
  }

  syncGame(gameId: string, period: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/games/sync-game`, {
      gameId,
      period
    });
  }

  validateGame(gameId: string): Observable<ValidationCheck[]> {
    return this.http.get<ValidationCheck[]>(
      `${this.baseUrl}/games/${gameId}/validate`
    );
  }

  timeOnIceReport(gameId: string) {
    return this.http.get<TimeOnIceReport>(
      `${this.baseUrl}/games/${gameId}/time-on-ice-report`
    );
  }

  updateStatus(gameId: string, status: GameStatus) {
    const progress: GameProgress = this.getGameProgressOnStatusChange(status);
    return this.http.patch<void>(`${this.baseUrl}/games`, {
      _id: gameId,
      status,
      progress
    });
  }

  allTags() {
    return this.http.get<string[]>(`${this.baseUrl}/games/tags`);
  }

  adjustLineup(
    gameId: string,
    existingPlayerNumber: string,
    existingPlayerId: string,
    newPlayerNumber: string,
    newPlayerId: string
  ) {
    return this.http.post(`${this.baseUrl}/games/adjust-lineup`, {
      gameId,
      existingPlayerNumber,
      existingPlayerId,
      newPlayerNumber,
      newPlayerId
    });
  }

  getTeamByPlayerName(game: Game, player: string): string {
    if (this.getHomeTeamPlayers(game).indexOf(player) > -1) {
      return game.homeTeam;
    } else if (this.getAwayTeamPlayers(game).indexOf(player) > -1) {
      return game.awayTeam;
    } else {
      return null;
    }
  }

  runCVPipeline(
    gameId: string,
    liveMode: boolean,
    periods: string,
    phase: string
  ): Observable<void> {
    return this.http.post<any>(`${this.gamesUrl}/${gameId}/run-cv-pipeline`, {
      liveMode,
      periods,
      phase
    });
  }

  runAggregation(gameId: string): Observable<any> {
    return this.http
      .post<{ message: string; taskId?: string; error?: string }>(
        `${this.gamesUrl}/${gameId}/run-aggregation`,
        {}
      )
      .pipe(
        map((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          return res;
        })
      );
  }

  importGoalsFromSihfReporter(
    gameId: string,
    options: { scorer: boolean; assists: boolean }
  ): Observable<any> {
    return this.http
      .post<any>(`${this.gamesUrl}/${gameId}/import-goals`, options)
      .pipe(
        map((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          return res;
        })
      );
  }

  importPenaltiesFromSihfReporter(gameId: string): Observable<any> {
    return this.http
      .post<any>(`${this.gamesUrl}/${gameId}/import-penalties`, {})
      .pipe(
        map((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          return res;
        })
      );
  }

  findPeriodByKeyCode(keyCode: number): GamePeriod {
    return periodKeyCodeMap[keyCode];
  }

  getGameTitle(game: Game) {
    return `${this.datePipe.transform(game.date, 'yyyy-MM-dd')} ${
      game.homeTeam
    } - ${game.awayTeam}`;
  }

  getGameProgressOnStatusChange(status: GameStatus): GameProgress {
    switch (status) {
      case GameStatus.NEW:
        return GameProgress.BEFORE_START;
      case GameStatus.COMPLETE:
        return GameProgress.ENDED;
    }
  }
}
